  <template>
    <div>
      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'types-of-digital-forms-you-can-create-using-record-times-docket-book'
        "
      >
        <your-ultimate-docket></your-ultimate-docket>
      </v-container>

      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'record-time-for-plumbing-business'
        "
      >
        <plumbing-business></plumbing-business>
      </v-container>

      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'vehicle-safety-checks-and-list-of-pre-start-checklist'
        "
      >
        <types-of-pre-start-checklist></types-of-pre-start-checklist>
      </v-container>
      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'record-time-qr-code'
        "
      >
        <qr-code></qr-code>
      </v-container>

      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'jobsite-manager'
        "
      >
        <jobsite-manager></jobsite-manager>
      </v-container>
      
      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'document-management-for-businesses'
        "
      >
        <document-management></document-management>
      </v-container>

      <v-container
        class=" text-container"
        style="display: block;"
        v-if="
          url_data ==
            'employee-time-clock-software'
        "
      >
        <br /><br />
        <img
          :src="`${publicPath}assets/blogs/employee-time-clock-software.webp`"
          alt="employee time clock software for a cleaning company"
          style="width:100%"
        />
        <br />
        <br />
        <h1>
          Employee Time Clock Software for Cleaning Business Efficiency
        </h1><br />
        <p>
          If you're still relying on pen and paper or Excel spreadsheets to record
          your employee's work hours, here's some good news: Record TIME offers a hassle-free, efficient, and accurate employee time clock system for your staff's time-tracking needs. Its <a href="https://recordtime.com.au/field-staff-real-time-tracking">field staff real time tracking</a> solution brings
          automation and accuracy to the forefront of your cleaning business. With
          Record TIME, you can ensure accurate and automated timesheets,
          streamline payroll processes, and improve overall productivity for your
          large or small businesses. Additionally, the application incorporates geofencing
          technology, enabling you to verify the precise location of your
          employees while they are working. <br /><br />Furthermore, Record TIME
          offers comprehensive business management solutions for the cleaning
          industry. It includes useful features such as clock-in/out, pre-start
          checklist, staff time tracking, incident report, cleaning request, job
          scheduling, digital docketing, and proof of work completion. This
          time clock software can be accessed on mobile,
          desktop, or tablet devices.
        </p>
        <br />
        <h2>
          Benefits of Using Employee Time Clock Software for Workforce Management
        </h2><br />
        <p>
          Record TIME offers many advanced features that help to address the
          challenges cleaning companies face in managing their workforce.
          Consequently, it allows you to make more intelligent decisions about how
          you schedule your staff and recurring cleaning jobs. As a result, the
          <a href="https://recordtime.com.au/cleaning-business-software-australia">cleaning sector</a> can benefit significantly from Record TIME. To elaborate
          on this, some of the benefits are:
        </p>
        <h3>Precise Time Tracking</h3><br />
        <p>
          Record TIME ensures accurate recording of employee work hours, including
          the precise time of clock-in and clock-out. As a result, this
          facilitates payroll management and eliminates conflicts or disagreements
          concerning the number of hours worked.
        </p>
        <h3>Effective Employee/ Job Scheduling</h3><br />
        <p>
          Record TIME lets cleaning companies schedule their staff and jobs based
          on availability, qualifications, proximity, and job sites. Consequently,
          this ensures that all jobs are properly scheduled and completed on time.
        </p>
        <h3>Easier Payroll Processing</h3><br />
        <p>
          Integration of employee time clock software with a <a href="https://recordtime.com.au/custom-invoice-book">custom invoice book</a>
          system streamlines the payroll system. This not only terminates the
          need for manual calculation but also lowers mistakes, guaranteeing
          accurate and timely wage payments based on reported hours.
        </p>
        <h3>Proof of Work Completion</h3><br />
        <p>
          With an employee time clock, employees can capture proof of work completion through
          functions like geolocation, timestamps, job-specific documentation,
          and visual evidence. This time clock solution helps in task management and
          increases client trust and satisfaction.
        </p>
        <h3>Simplified Work Process</h3><br />
        <p>
          Record TIME's digital docketing approach allows employees to keep
          records of job-related information, make notes, edit PDF documents, and
          upload visual proof. Time clock systems enable users to effortlessly create digital
          forms and docket templates, making it incredibly easy to duplicate any
          documentation. Furthermore, it simplifies operations, improves
          communication, and provides useful information for better
          decision-making.
        </p>

        <br />
        <div class="left-blog1">
          <h2>
            Employee Time Clock Software: Your Solution to Precise Staff Time
            Tracking
          </h2><br />
          <p><b>Manage All Work From One Place</b></p>
          <p>
            Record TIME offers companies a powerful solution for tracking employees and monitoring their time and attendance. With employee time tracking and location monitoring,
            employers can easily keep track of their employee working hours and
            ensure accurate attendance records. The software's pre start checklist
            feature enables businesses to establish standardised procedures and
            enhance efficiency while ensuring adherence to important tasks and
            safety protocols. By eliminating manual paperwork through digital
            forms and dockets, Record Time enables faster data collection and
            sharing in real time. <br />
            <br />Moreover, remote approval features facilitate timely
            decision-making and increase flexibility for teams working in
            different locations. Overall, Record Time streamlines operations,
            optimises productivity, and enhances workforce management, allowing
            companies to effectively organise their business and accurately bill
            clients based on reliable time tracking data. Whether you are looking for robust software for project management or need a mobile app to manage your cleaning staff, Record TIME has the solutions to manage time, track time, and employee attendance.







          </p>
          <br />
          <v-btn
            color="secondary"
            height="55"
            :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
            @click="$router.push({ name: 'staffTimeTracking' })"
            class="mr-7 btn-1"
            elevation="0"
          >
            Read More
          </v-btn>
        </div>

        <div class="right-blog1">
          <img
            :src="`${publicPath}assets/blogs/employee-and-job-scheduling.webp`"
            alt="employee and job scheduling with record time"
          />
        </div>
        <div style="clear:both;"></div>
        <div class="text-container">
          <div class=""></div>
          <div class="right"></div>
          <div style="clear:both;"></div>
        </div>
      </v-container>
      <v-container
        class=" text-container"
        style="display: block;"
        v-if="url_data == 'best-construction-scheduling-software'"
      >
        <br /><br />
        <img
          :src="`${publicPath}assets/blogs/construction-scheduling-software.webp`"
          alt="the best construction scheduling software for streamlined project workflow"
          style="width:100%"
        />
        <br />
        <br />
        <h1>Best Construction Scheduling Software for Streamlined Project Workflow</h1>
        <br>
        <p> <b>Record TIME is the best construction scheduling software that effectively handles and automates project workflow in the construction industry. It serves various stakeholders such as general contractors, home builders, building contractors, and subcontractors. 
          </b>
          <br /><br/>The primary goal of Record TIME is to digitalise the documentation process in the construction industry. Additionally, it provides an effective solution for project scheduling, job scheduling, staff scheduling, and plant and equipment scheduling in construction job sites. It also aims to oversee projects and contracts and facilitate effective communication regarding work progress. Moreover, users can leverage this software to create pre start checklist, <a href="https://recordtime.com.au/custom-invoice-book">custom invoice book</a>, send daily dockets, and provide updates on work progress.</p>
              
              <br /> 

        <div> <h2> Manage Construction Infrastructure Projects, Processes, and Employees with Innovative Construction Scheduling Software </h2>
          <br>
          <p>Record TIME allows construction professionals or <a href="https://recordtime.com.au/geotechnical-engineer-app">geotechnical engineers</a> to effectively handle multiple projects in different job sites, ensuring they stay on track and within the project budget. Additionally, it enables users to conveniently manage projects, schedule and monitor plant and equipment   maintenance, and conduct pre start inspections. With Record TIME, users can schedule jobs and staff as per location and availability using advanced scheduling tools.
  <br /><br />
  In the past, the docketing procedure involved piles of paperwork, countless spreadsheets, and unlimited hours spent on organising and managing. However, thanks to Record TIME, the construction industry now has access to effective technologies that simplify and organise the docketing process.
            <br /><br />
            One of the key advantages of Record TIME's software for construction is its potential to smooth cooperation between contractors, subcontractors, and project teams. With a centralised platform, everyone involved in the project has access to project information, such as plans, specifications, and equipment data. This construction project scheduling software ensures that all task dependencies are accounted for, reducing delays and improving project execution.
            <br /><br />
            Therefore, Record TIME is considered the best construction scheduling software for the construction industry. Digital docketing, employee time tracking, pre start checklist, and <a href="https://recordtime.com.au/proof-of-work-completion">proof of work completion</a> are some of the powerful aspects of Record TIME. Users can create any type of digital form and docket and use them as a PDF. Moreover, everyone involved in the project can access this data.
          </p>
        </div>

        <div>
          <img
            :src="
              `${publicPath}assets/blogs/Why-record-time-for-construction-management-software.webp`
            "
            alt="benefits of construction scheduling software"
          />
        </div>
        <div style="clear:both;"></div>
        <div class="text-container">
          <div class="">
            <br /><br />
            <h2>Why Record TIME is the Best Construction Scheduling Software?
            </h2>
            <br>
            <p>
              Record TIME has gained popularity among construction businesses worldwide due to its wide array of features and easy-to-use design. Let's explore the key features of Record TIME that make it an essential project management tool for construction:
            </p>

            <h3>Scheduling Feature</h3>
            <p>Keeping track of various jobs, equipment, deadlines, and employees can be challenging in construction projects. However, Record TIME simplifies this process with its construction planning and scheduling feature. With Record TIME construction scheduling, managers can create detailed project schedules, assign tasks to team members, and set deadlines. In addition, you can also schedule and manage plant and equipment in the job fields. As a result, the scheduling tool of Record TIME significantly improves project coordination and reduces delays, ensuring efficient project execution.
            </p>
            <h3>Digital Docketing (Digital Forms and Docket Templates)</h3>
            <p>Record TIME offers digital docketing capabilities, highly regarded among its features. With Record TIME, the construction industry can eliminate the hassle of paperwork and manual documentation. Additionally, Record TIME provides a variety of easy-to-customise digital forms and docket templates to meet specific construction project needs. With the digital docketing approach, document management is digitalised, saving time and reducing errors. 
            </p>

            <h3>Proof of Work Completion with Visual Evidence</h3>
            <p>Record TIME enables employees to provide solid evidence of their involvement in a specific job. Employees can enhance trust among contractors and clients by sending photos of completed tasks as proof of work completion in real time.
            </p>

            <h3>Pre Start Checklist</h3>
            <p>
              Record TIME has a list of templates for various pre start
              checklists, including vehicles, forklifts, <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">excavators</a>, trucks, and
              many others. These checklist templates cover a range of critical
              tasks that must be addressed before starting a job at the
              construction site. <br /><br />In addition, the pre start inspection
              serves as a roadmap for safety management in construction
              businesses. It enables users to address important factors and reduce
              the risk of setbacks or delays. Ultimately, it establishes the
              groundwork for a successful construction project.
            </p>

            <h3>Staff Time Tracking</h3>
            <p>
              Time is a valuable resource in the construction business, and
              effective time management is vital for meeting deadlines and staying
              within budget. Record Time offers robust time tracking capabilities,
              allowing construction teams to record the time spent on specific
              project tasks  accurately.
            </p>

            <h3>Construction Jobsites Management </h3>
            <p>
              For construction companies with multiple job sites or teams working
              remotely, location tracking is crucial. Our construction scheduling
              software offers GPS-based location tracking, task management, and allows project managers to monitor the real-time location of their teams.
            </p>

            <h3>Remote Approval of Dockets</h3>
            <p>
              The traditional process of document and invoice approval can be
              time-consuming and prone to delays. Record TIME streamlines this
              process by offering remote approval of dockets. <br /><br />The project
              manager and clients can review and approve dockets and invoices
              from any location. This eliminates the need for physical paperwork
              and speeds up the payment process. This construction project management software enhances financial
              control, accelerates cash flow, and strengthens collaboration
              between project teams and clients.
            </p>
            <br>

            <h2>Best Construction Scheduling Software: Strong Accountability, Digital Docketing, Remote Approval, and Easy Collaboration </h2>
            <br>
            <p>
              Investing in the right scheduling software is a
              game-changing decision for managing projects. Record TIME is convenient and safe to use as it is available in mobile apps for your job site to manage projects. Without a
              doubt, it is suitable for all builders and contractors who are
              looking to digitise and organise their docketing processes.
              <br /><br />Record TIME is a construction management software that saves time and offers a centralised platform for managing
              documents. Additionally, this construction project schedule simplifies teamwork by enabling real
              time docket sharing, updating, enhancing communication, and reducing
              mistakes. <br /><br />Construction scheduling software, such as
              Record TIME, offers a comprehensive suite of project management tools. These tools
              facilitate smooth project planning, scheduling, budgeting, and
              communication. Try Record TIME with our free trial and see the difference it can make for your construction projects!
            </p>
            <br>

            <v-btn
              color="secondary"
              height="55"
              :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
              @click="$router.push({ name: 'jobsiteManagement' })"
              class="mr-7 btn-1"
              elevation="0"
            >
              Read More
            </v-btn>
          </div>
          <div class="right"></div>
          <div style="clear:both;"></div>
        </div>
      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="url_data == 'pre-start-checklist-template'"
      >
        <br /><br />
        <img
          :src="`${publicPath}assets/blogs/pre-start-checklist-template.webp`"
          alt="use customisable pre start checklist template and ensure safety protocol"
          style="width:100%"
        />
        <br />
        <br />
        <h1>
          Enhancing Workplace Safety with Customisable Pre Start Checklist
          Template
        </h1>
        <p>
          <b
            >Every organisation, regardless of size or sector, should prioritise
            safety. Strict adherence to safety protocols is vital to prevent
            accidents, boost productivity, and foster a healthy working
            environment. Record TIME is a proven tool for achieving this goal.
            With its comprehensive list of pre start checklist template, you can
            create a pre start and safety inspection before starting work, incorporating a thorough risk assessment and corrective actions.</b
          >
          <br /><br />
          Employees need a pre start checklist before initiating any work to
          ascertain that equipment and workplace conditions are good. Furthermore,
          these checklists guide workers through systematic checks of all safety
          measures, ensuring that no crucial point is overlooked.
          <br /><br />
          However, the real challenge frequently resides in developing pre start
          checks that are congruent with your unique operational requirements.
          Generic checklists often fall short, leaving potential hazards
          unchecked. This is where Record TIME comes into the picture.
        </p>
        <br />
        <h2>What is the Pre Start Checklist?</h2>
        <p>
          A pre start checklist is a systemic list of items and parts of machines
          that need to be carefully inspected before starting any work operations.
          Automotive, construction, and manufacturing businesses that rely on
          machinery, vehicles, and equipment must conduct regular inspections and
          maintenance checks. Moreover, these tasks are essential both before and
          after operations. These companies must establish a pre start checklist
          to organise the process and detect potential risks. Additionally, this
          checklist ensures that all necessary preparations are completed. Record
          TIME offers a pre start checklist template for:
        </p>
        <ul>
          <li>Vehicle pre start checklist</li>
          <li>Forklift pre start checklist</li>
          <li><a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">Excavator pre start checklist</a></li>
          <li>Truck pre start checklist</li>
          <li>Ewp pre start checklist</li>
          <li>And many more...</li>
        </ul>


        <br />
        <h2>Benefits of Using Record TIME for Pre Start Inspection</h2>
        <br />
        <p>
          <b>Increase Workplace Productivity:</b> <br />
          Pre start checklist make sure that all necessary supplies, tools, and
          equipment are available and in good working condition.
        </p>

        <p>
          <b>Enhanced Safety: </b> <br />
          In the manufacturing and construction sectors, the well-being of
          employees comes first. By using pre start checklist, you can find
          possible risks and make sure that safety precautions are in place before
          work starts.
        </p>

        <p>
          <b>Adherence to Regulations: </b> <br />
          The manufacturing and construction industries are subject to a multitude
          of laws and norms. Pre start check helps to ensure adherence to
          rules like construction codes, environmental regulations, and
          occupational health and safety standards.
        </p>

        <p>
          <b>Financial Benefits:</b> <br />
          Pre start checklist helps to identify potential risks so they can be avoided early on. It can also help prevent accidents and damage to equipment. Additionally, our pre start checklist templates ensure that all relevant equipment, materials, and supplies are on hand, reducing downtime and enhancing operational effectiveness.
        </p>

        <div class="text-container">
          <div class="left">
            <h2>
              Digital Pre Start Checklist Template to Enhanced Compliance and
              Employee Well-Being
            </h2>
            <p>
              Record TIME is a groundbreaking digital docketing platform that
              enables organisations to create and customise their own pre start
              and safety checklists. We acknowledge the fact that every business
              is distinct and a one-size-fits-all solution is not always
              appropriate. Seeing that, Record TIME provides a user-friendly
              interface, allowing users to personalise their checklist in line
              with their specific needs and industry stipulations.
              <br /><br />
              The platform is designed to simplify and optimise safety procedures.
              With Record TIME, you can develop a checklist that addresses every
              potential hazard in your workplace. The interactive and intuitive
              interface makes it easy to amend the checklist for your evolving
              needs and new regulations. Furthermore, Record TIME offers easy
              access to these checklists from any device. This digital advancement
              enhances the efficiency of safety checks and eradicates the risk of
              losing or misplacing physical copies of checklists. The platform
              also keeps an automated record of completed pre start checklist,
              enabling straightforward review and monitoring of compliance.
              <br /><br />
              By using Record TIME, you're not merely customising your safety
              procedures but also cultivating a culture of safety within your
              organisation. Our pre start checklist template allows
              you to communicate effectively about safety concerns. This checklist
              also ensures that all employees understand their roles in maintaining
              safety and fosters a sense of accountability amongst everyone.
              <br /><br />
              <b
                >In business, there is no better investment than the safety and
                well-being of your team members. Thus, investing in a digital pre
                start and safety checklist via Record TIME is not just an
                administrative chore—it’s a commitment to the welfare of your
                team.</b
              >
            </p>

            <button type="button">
              <a
                href="https://recordtime.com.au/blog/plant-pre-start-checklist-template"
                >How to design a Pre Start Checklist</a
              >
            </button>
          </div>
          <div class="right">
            <img
              :src="
                `${publicPath}assets/blogs/why-record-time-for-pre-start-checklist.webp`
              "
              alt="benefits of using record time for pre start checklist and inspection"
            />
          </div>
          <div style="clear:both;"></div>
        </div>
      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="url_data == 'digital-docketing-with-record-time'"
      >
        <br /><br />
        <img
          :src="
            `${publicPath}assets/blogs/digital_docketing_with_record_time.png`
          "
          alt=" Digital docketing simplifies the paperwork, improves accuracy and save cost"
          style="width:100%"
        />
        <br />
        <br />
        <h1>Harnessing the Power of Digital Docketing with Record Time</h1>
        <p>
          The digitisation of business processes has been an ongoing trend for
          several years. The significant improvement seen due to this trend is
          digital docketing, the process of managing, tracking, and documenting
          business activities online. One such service leading the charge in this
          transformation is Record TIME, a digital docketing system that
          revolutionises the way businesses handle their dockets digitally.
        </p>
        <div class="text-container">
          <div class="left">
            <h2>Benefits of Record Time's Digital Docketing</h2>
            <p>
              Record Time's digital docketing solution offers numerous benefits to
              businesses of all sizes. You can create dockets digitally and share
              them online in real time. Moreover, Record TIME provides variety of
              docket templates to make your work easy.
            </p>
            <p>
              Firstly, efficiency is at the core of Record Time. It automates the
              traditional docketing process, eliminating the need for physical
              paperwork, which can often lead to errors, misplacements, and
              delays. This platform lets you create, send, and approve digital
              dockets in real time. This saves you valuable time, allowing you to
              focus on more critical aspects of your business processes.
            </p>
            <p>
              Secondly, Record Time brings accuracy and transparency to the table.
              Thanks to its digital approach, every docket's information is clear,
              accurate, and easy to understand. Moreover, it provides a complete
              history of all transactions, making audit trails effortless. You can
              quickly locate specific dockets or data digitally, ensuring no
              detail falls through the cracks.
            </p>
            <p>
              A noteworthy benefit of Record Time is its mobility. With a
              cloud-based infrastructure, you can access your docket data from any
              device, at any time, and from anywhere. This accessibility enhances
              team collaboration, as all members can view and update docket
              information in real time.
            </p>
            <p>
              Additionally, Record Time provides an excellent solution for
              environmental sustainability. With its digital approach, Record TIME
              eliminates the need for paper in docketing processes. Businesses can
              significantly reduce their carbon footprint. Undoubtedly, this will
              contribute to environmental protection and also enhance your brand
              image as a responsible, eco-friendly business.
            </p>
            <p>
              Last but not least, the platform bolsters cost savings; you can
              reduce manual errors, decrease paper usage, and boost overall
              project efficiency. With Record TIME's digital docketing approach
              you're bound to notice a positive impact on your bottom line.
            </p>
            <p>
              In summary, Record Time presents a remarkable opportunity for
              businesses to streamline their operations digitally. It offers a way
              to improve efficiency, enhance accuracy and transparency, promote
              mobility, champion environmental sustainability, and realise
              substantial cost savings. Make the switch to Record Time and
              transform your docketing process to digital today.
            </p>
            <button type="button">
              <a href="https://recordtime.com.au">Know More</a>
            </button>
          </div>
          <div class="right">
            <img
              :src="
                `${publicPath}assets/blogs/record-time-for-digital-docketing.webp`
              "
              alt="benefits of record time's digital docketing"
            />
          </div>
          <div style="clear:both;"></div>
        </div>
      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="url_data == 'business-management-solution'"
      >
        <br /><br />
        <img
          :src="`${publicPath}assets/blogs/business-management-solution.webp`"
          alt="business management solutions for owner operator"
          style="width:100%"
        />
        <h1>Business Management Solution for Owner Operators</h1>
        <p>
          In the fast-paced business environment of today, owner-operators face several difficulties in effectively operating their businesses. From guaranteeing on-time delivery to balancing administrative duties and optimising routes, the task can be overwhelming. However, with Record TIME, owner operators have access to top business management software that provides an effective business management solution for every task management need.

  <br /><br />
  If you own a business and operate machines like Backhoes, <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">Excavators</a> or Skid-Steers, Forklifts or drive a truck, using Record TIME can help you with many aspects of your business operations and make informed decisions. Record TIME offers real time data and real time visibility, ensuring you have a comprehensive business management platform at your disposal.
 

        </p>
        <h2>
          Understand the Importance of Business Management Solution: Smooth Operation, Better Communication, Paperless Work, Easy Scheduling
        </h2>
        <br />
        <h2>Digital Docketing</h2>
        <p>
          First of all, most job sites require filling out a daily docket or an
          hourly hire docket. Record TIME allows users to create hourly dockets
          simply with a drag-and-drop method. Users can customise their digital
          forms and dockets to their specific needs from scratch. Additionally,
          you can create a daily docket within a second since Record Time offers a
          wide variety of form templates.
        </p>
        <h2>Proof of Delivery</h2>
        <p>
          For any owner operators, proof of delivery is an important part of a
          business management solution. It guarantees delivery verification, helps
          in settling disputes, improves workforce management, increases customer satisfaction, and boosts
          business effectiveness. As a result, owner operators can streamline
          their business management system procedures and guarantee the prosperity and
          expansion of their companies.
          <br /><br />
        If your business is involved in any kind of delivery (i.e.,
          delivering soil) or in any logistics, then you can use Record TIME as a
          <a href="https://recordtime.com.au/proof-of-delivery-app">Proof of Delivery system</a>. The docket includes details like client
          information, an automatically geotagged location, images of delivered
          items, and an e-signature of the recipient. Additionally, you can add
          any other management solution details that are relevant to your business processes using
          Record TIME’s flexibility.
        </p>
        <h2>Safety and Compliance</h2>
        <p>
          Furthermore, safety and compliance become very handy with Record TIME.
          You can easily create a simple and effective pre-start checklist for any
          machine or vehicle. In addition to this, you can create Work Method
          Statements (SWMS), Toolbox Talks, and Take 5 Talks as templates.
          Moreover, you can upload and share these templates as pdfs with relevant
          parties.
        </p>
        <h2>Custom Invoice Book</h2>
        <p>
          Finally, the dreaded invoice. How can you create an invoice using
          approved daily hire dockets? Simple, do it with Record TIME. Once you
          send a docket, your client can remotely approve it in real time.
          <br /><br />
          After approval, Record TIME automatically compiles an invoice. This
          <a href="https://recordtime.com.au/custom-invoice-book">custom invoice book</a> will have the totals tallied up  as well as the relevant
          dockets attached to it. This way, the client can easily verify the
          invoice, and you can get paid on time, quickly, and without much hassle.
        </p>
        <br />
        <h2 style="color:#1bb0b9;">
          Invest in Digital Business Management Solution to Enhance Your Productivity
        </h2>
        <img
          :src="
            `${publicPath}assets/blogs/complete-business-management-solution.webp`
          "
          alt="business management solution to Strengthen Your Business"
          style="width:50%"
        />
        <br />

        <p>As a small business owner-operator, embracing Record TIME's management system is akin to ushering in a digital era of operational excellence. Record TIME's integrated software solutions span the full spectrum of managing day-to-day activities to orchestrating complex supply chain dynamics, cultivating strong customer relationships, executing efficient project management, and handling employee management, inventory management, and resource management. 
          <br /><br />
  Record TIME stands as paradigm-shifting <a href="https://recordtime.com.au/about">business management software</a> that empowers owner-operators to not only manage their ventures but also propel them toward unprecedented growth and prosperity. By embracing Record TIME's business management solution, you can streamline operations, enhance customer services, assign tasks seamlessly, manage team members, and optimise your supply chain, all while reaping the benefits of a cloud based framework.
  </p>

        <h2>Get a Free Trial</h2>
        <p>
          If your business operations require any of the above, then you should sign up for
          a free 30-day trial today. Furthermore, these business management tools will save you time and money, enhance customer service, and are ideal for business management. It will also help you remain on top of your paperwork and reach your maximum potential with the least amount of disruption to your management operations. This effective management technique will assist you in streamlining your business operations and realising their full potential. Sign up today.
        </p>

        <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"   @click="$router.push({ name: 'yourUltimateDocket' })" class="mr-7 btn-1" elevation="0">
          Read More
        </v-btn>

      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="
          url_data ==
            'revolutionising-delivery-business-with-proof-of-delivery-software'
        "
      >
        <h1>
          Revolutionising Delivery Operations with Powerful Proof of Delivery
          Software
        </h1>
        <p>
          Delivery businesses often grapple with challenges such as communication
          and proof of delivery accuracy. These hurdles can impact customer
          satisfaction and operational efficiency. However, implementing proof of delivery software can effectively tackle these issues and enhance customer satisfaction.
          With this management system, users can seamlessly affix digital signatures and
          incorporate visual evidence onto the proof of delivery docket with real time tracking. This
          electronic proof of delivery docket not only gives peace of mind to both
          sender and receiver but also performs an essential role in solving
          disagreements or declarations. Use our delivery management solutions to enhance accuracy,
          accountability, complete visibility, and security with the <a href="https://recordtime.com.au/proof-of-delivery-app">proof of delivery app</a>.
        </p>
        <br /><br />
        <h2>How to Design an Electronic Proof of Delivery Docket</h2>
        <p>
          Today we will design a proof of delivery docket that can be used by any
          logistics company or anyone involved in delivering items. This docket
          template will encompass essential elements such as capture electronic
          signatures, photographs of the delivered items, along with various other
          pertinent details to make the delivery process seamless. To begin the process of this proof of delivery software, let's delve into the key
          components of this docket template.
        </p>
        <ul style="list-style-type:none;">
          <li>
            <p>
              1. First, log on to Record TIME using your details as shown
              below:
            </p>
            <img
              :src="`${publicPath}assets/blogs/record-time-app.webp`"
              alt="login to record time app for the design of proof of deliovery docket"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              2. Then, click Docket Book Manager and then click Docket Templates
              as shown below:
            </p>
            <img
              :src="`${publicPath}assets/blogs/docket-book.webp`"
              alt="electronic proof of delivery dockets templates available in record time app"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>3. Click Add New</p>
          
            <p>And call your docket, delivery docket.</p>
          </li>
          <li>
            <p>
              4. Add a short text field by clicking the short text field box from
              the docket elements box.
            </p>
            <img
              :src="`${publicPath}assets/blogs/docket-elements.webp`"
              alt="design your proof of delivery dockets as per your need with recod time"
              style="width:100%;height:350px;object-fit: contain;"
            />
            <p>Call this template. “Delivery Driver” as shown below.</p>
            <img
              :src="`${publicPath}assets/blogs/dockets-delivery.webp`"
              alt="call the template to send proof of delivery dockets "
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              5. Next, press the plus button shown above to add some names to the
              pre filler. Pre fillers are drop down lists that you can set-up so
              that the end users can just select from a list. Add a few names:
            </p>
            <img
              :src="`${publicPath}assets/blogs/pre-filler.webp`"
              alt="select the end users from the available list"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              6. Add another short text field called “Client” and a location field
              called “Delivery Location”. You can use pre fillers here as well to
              load some common clients and locations. Finally, go ahead and add a
              long text field called “Description of Items”:
            </p>
            <img
              :src="`${publicPath}assets/blogs/delivery-location.webp`"
              alt="add clients and locations with the description of dockets"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              7. Next, add an image field called “Image of Items” and a signature
              field called “Recipient Signature”:
            </p>
            <img
              :src="`${publicPath}assets/blogs/image-of-item.webp`"
              alt="delivery confirmation with your digital signature"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              8. That's it! Finally, you have successfully designed your first
              electronic proof of delivery docket.
            </p>
            <img
              :src="
                `${publicPath}assets/blogs/proof-of-delivery-record-time.webp`
              "
              alt="create an electronic proof of delivery with record time "
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
        </ul>
        <br />
        <p>This comprehensive delivery management software provides all the tools necessary to ensure accurate and efficient proof of delivery documentation in any logistics operations. Businesses can track deliveries in real time and provide undeniable proof of delivery solutions with this delivery software. </p>
        <v-btn
          color="secondary"
          height="55"
          :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
          @click="$router.push({ name: 'cleaningBusiness' })"
          class="mr-7 btn-1"
          elevation="0"
        >
          Read More
        </v-btn>
      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="url_data == 'plant-pre-start-checklist-template'"
      >
        <br /><br />
        <h1>Easy to Create: Digital Plant Pre Start Checklist Template</h1>
        <p>
          Creating a <b>Plant Pre Start Checklist</b> involves generating a
          complete list of inspections, potential issues, and maintenance needs
          before starting work on a job site. Additionally, it is a significant
          component for all industries where accuracy and reliability are
          essential. With Record TIME's plant pre start checklist template, you
          can ensure that the equipment is safe, effective, and compliant helping to reduce the risk of accidents and operational failures.
          <br /><br />Ensuring the smooth operation of plants is vital for
          maximising efficiency and productivity in any industry. Operators and
          managers can effectively address potential safety risks, equipment
          problems, and environmental issues by systematically reviewing key
          aspects of the plants. This proactive approach not only ensures the
          safety of the employees but also improves operational efficiency and
          minimises downtime. To improve plant care and maintenance in your
          industry, invest in the implementation of a thorough plant pre start
          checklist template.
        </p>

        <h2>Benefits of Using a Plant Pre Start Checklist Template</h2>
        <p>
          Designing a plant pre start checklists for equipment like forklifts,
          vehicles, <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">excavators</a>, or trucks is important for safety and regular
          maintenance. With <a href="https://recordtime.com.au/">Record TIME</a>, it’s so easy to create any pre start
          checklist, you can use templates available in the dashboard or simply
          create one by yourself using drag and drop technique. Additionally, it
          uses a digital docketing approach to create these pre start checks,
          which are shareable through one click.
        </p>

        <h2>How to Conduct Pre Start Inspection for Plants with Record TIME?</h2>
        <p>
          Today we will design a basic <a href="https://recordtime.com.au/blog/pre-start-checklist-template">pre start checklist template</a>. The checklist
          will look like the following:
        </p>
        <img
          :src="`${publicPath}assets/blogs/check-list-for-work.webp`"
          alt="improve workplace safety with a plant pre start checklist"
          style="width:100%;height:350px;object-fit: contain;"
        />
        <ul style="list-style-type:none;">
          <li>
            <p>1. Log on to Record TIME using your details:</p>
            <img
              :src="`${publicPath}assets/blogs/login-page.webp`"
              alt="log in to record time to get templates for your plant pre start checklist"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>2. Click Docket Book Manager and then click Docket Templates:</p>
            <img
              :src="`${publicPath}assets/blogs/docket-template.webp`"
              alt="docket template option in record time"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>3. Click Add new</p>
            <img
              :src="`${publicPath}assets/blogs/dockets.webp`"
              alt="add new docket elements from docket book manager"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              4. Fill in the title “Pre Start Checklist” or whatever you would
              like to name this template:
            </p>
            <img
              :src="`${publicPath}assets/blogs/checklist.webp`"
              alt="pre start checklist option in record time app"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              5. In The screen below the first box (1) represents the docket book.
              These include items like short text, long text date, and so on. The
              Box number (2), has some parameters, tick invoiceable if you want to
              use these dockets in invoices.
            </p>
            <img
              :src="`${publicPath}assets/blogs/docket-elements-record-time.webp`"
              alt="add template dockets"
              style="width:100%;height:350px;object-fit: contain;"
            />
            <img
              :src="`${publicPath}assets/blogs/docket-info.webp`"
              alt="fill the docket info in record time app"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              6. Click Short Text on Box 1 and the design screen should now look
              like:
            </p>
            <img
              :src="`${publicPath}assets/blogs/label-text-for-docket.webp`"
              alt="label text to understand how to edit your pre start checklist template"
              style="width:100%;height:350px;object-fit: contain;"
            />
            <p>Double click the text to edit the label text:</p>
            <img
              :src="`${publicPath}assets/blogs/edit-docket.webp`"
              alt="enter label text to edit docket"
              style="width:100%;height:350px;object-fit: contain;"
            />
            <p>Given that, the field should now look like this.</p>
            <img
              :src="`${publicPath}assets/blogs/after-edit.webp`"
              alt="pre start checklist after edit"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              7. Add a location field, a short text field, and a number field and
              change their labels to Job location and Machine Model and Working
              Hours respectively. At this time, your screen looks like this:
            </p>
            <img
              :src="`${publicPath}assets/blogs/machine-detail.webp`"
              alt="fill out the machine details like location, model and hours it is used"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              8. We will now add some checkbox fields from box (1) as shown. The
              items will be:
            </p>
            <ul>
              <li>Are all controls working properly?</li>
              <li>Is Reverse/Motion Alarm working?</li>
              <li>Is Hazard light working?</li>
              <li>Check for any fluid leaks</li>
            </ul>
            <p>
              These are the checkbox fields that are added, they look similar on
              your screen.
            </p>
            <img
              :src="
                `${publicPath}assets/blogs/benefits-of-pre-start-checklist.webp`
              "
              alt="pre start checklist for safety and maintenance"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              9. Our pre start checklist wouldn't be complete without notes,
              images of the machine, and the operator's electronic signature.
              Following, go ahead and add a short text field called “Notes”, an
              image field and call it “Images of Machine” and a signature field
              called “Operators Signature”. The screen now:
            </p>
            <img
              :src="
                `${publicPath}assets/blogs/machine-used-in-construction-company.webp`
              "
              alt="capture the condition of machine after work and sumbit with your checklist docket"
              style="width:100%;height:350px;object-fit: contain;"
            />
          </li>
          <li>
            <p>
              10. And there you have it, not only have you successfully created a
              basic plant pre start checklist but you can also make this pre start check
              more comprehensive by adding other elements.
            </p>
          </li>
        </ul>

        <button type="button">
          <a href="https://recordtime.com.au/blog/pre-start-checklist-template"
            >Know More</a
          >
        </button>
      </v-container>

      <v-container
        class="faq-notice-container"
        style="display: block;"
        v-if="url_data == 'add-all-your-licenses-and-qualifications-in-one-place'"
      >
        <br /><br />
        <h1>Add all your licenses and qualifications in one place</h1>
        <p>
          It is common for job sites these days to ask for various qualifications
          you may have. For example, some may ask for your asbestos awareness
          certificate, your driver's license and more. Further, governments have
          also begun asking for proof of covid-19 vaccinations. Most states
          require workers to have had at-least 1 shot of the vaccination and
          booked the second, in order to operate at a job site. Below, we show you
          how you can use <a href="https://recordtime.com.au/">Record TIME’s</a> brand new feature to track and add various
          types of qualifications to your account. It is a simple process and you
          can use the app to accomplish this.
        </p>
        <h3>HOW TO ADD LICENCES / QUALIFICATIONS TO YOUR ACCOUNT:</h3>
        <ul style="list-style-type:none;">
          <li>
            <p>
              1. Open the app and tap the 3 horizontal lines in the top left
              corner
            </p>
          </li>
          <li>
            <p>2. Tap "Profile"</p>
          </li>
          <li>
            <p>3. Then tap the section titled "Licences/Qualifications"</p>
          </li>
          <li>
            <p>4. Press the blue "+" button to input your first document</p>
          </li>
        </ul>
        <p>
          NB: You can share your documents with your employers over multiple
          companies & as well as yourself. Save documents as PDF’s for printing or
          just safe keeping
        </p>
        <img
          :src="`${publicPath}assets/blogs/demo-account-record-time.webp`"
          alt="save your ids and certificates in one place"
          style="width:100%;height:350px;object-fit: contain;"
        />
        <img
          :src="`${publicPath}assets/blogs/certificates-required-for-job.webp`"
          alt="make your work profile strong with all the necessary documents"
          style="width:100%;height:350px;object-fit: contain;"
        />
        <span class="row" style="padding-top:40px">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <img
              :src="require('../assets/blogs/3/4.png')"
              style="width:20%;height:75px;object-fit: contain;"
            />
            <h3>Easy Access</h3>
            <p>
              Documents are stored on a user's account. Access all your licences /
              qualifications / certificates by heading to your profile tab in the
              Record TIME app.
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <img
              :src="require('../assets/blogs/3/5.png')"
              style="width:20%;height:75px;object-fit: contain;"
            />
            <h3>Share your documents</h3>
            <p>
              For convenience, share your documents with your employers or even
              yourself. Documents can be saved as PDFs for printing or just
              safekeeping.
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <img
              :src="require('../assets/blogs/3/6.png')"
              style="width:20%;height:75px;object-fit: contain;"
            />
            <h3>Edit details easily</h3>
            <p>
              Update: expiry on licences, latest vaccinations dates, etc. without
              having to create a new document
            </p>
          </div>
        </span>
        <span>
          As you can see above in the second image, you have the option to
          generate a PDF document out of all of your qualifications. This allows
          you to share everything in one go, without having to spend too much time
          organising it. We hope you find this feature useful and if there is
          anything else you would like to know, just get in touch with us.
        </span>
      </v-container>
    </div>
  </template>

  <style lang="scss">
  .text-container {
    height: auto !important;
    .left {
      float: left;
      max-width: calc(100% - 530px);
      padding-right: 15px;
    }
    .right {
      width: 500px;
      float: right;
      img {
        max-width: 600px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left {
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right {
        float: none;
        max-width: 100%;
      }
    }

    .left-blog1 {
      float: left;
      max-width: calc(100% - 800px);
      padding-right: 15px;
    }
    .right-blog1 {
      width: 800px;
      float: right;
      img {
        max-width: 800px;
        width: 100%;
      }
    }

    .left-blog1-new {
      float: left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
    }

    .right-blog1-new {
      width: 600px;
      float: left;
      text-align: center;
      img {
        max-width: 300px;
        width: 100%;
      }
    }

    .right-blog2-new {
      float: left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
    }

    .left-blog2-new {
      width: 600px;
      float: left;
      text-align: center;
      img {
        max-width: 300px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1 {
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1 {
        float: none;
        max-width: 100%;
      }
    }
  }
  </style>

  <script>
  import YourUltimateDocket from "./Blogs/YourUltimateDocket.vue";
  import TypesOfPreStartChecklist from "./Blogs/TypesOfPreStartChecklist.vue";
  import PlumbingBusiness from "./Blogs/PlumbingBusiness.vue";
  import QrCode from "./Blogs/QrCode.vue";
  import JobsiteManager from "./Blogs/JobsiteManager.vue";
  import DocumentManagement from "./Blogs/DocumentManagement.vue";

  // @ is an alias to /src

  export default {
    components: {
      YourUltimateDocket,
      TypesOfPreStartChecklist,
      PlumbingBusiness,
      QrCode,
      JobsiteManager,
      DocumentManagement,
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
        metaTitle: "Record Time",
        metaDescription: "Record Time",
        metaKeywords: "Record Time",
        metaLink: "",
        url_data: "",
      };
    },
    metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: this.metaDescription },
        { name: 'keywords', content: this.metaKeywords },
      ],
      link: [
        { rel: 'canonical', href: this.metaLink }
      ]
    };
  },
    mounted() {
      this.url_data = this.$route.params.id;
      if (this.url_data == "digital-docketing-with-record-time") {
        this.metaTitle = "Digital Docketing | Organise Your Paperwork and Boost Productivity";
        this.metaDescription = "Discover the ideal digital docketing system for your business, and improve your practice by enhancing accuracy and transparency.";
        this.metaKeywords = "digital docketing";
        this.metaLink = 'https://recordtime.com.au/blog/digital-docketing-with-record-time';
      } 

      else if (this.url_data == "business-management-solution") {
        this.metaTitle = "Business Management Solution for Enhanced Productivity";
        this.metaDescription ="Business management solution to digitally enhance productivity by optimising, scheduling, managing and inspecting task for business operator.";
        this.metaKeywords = "business management solution";
        this.metaLink = 'https://recordtime.com.au/blog/business-management-solution';
      } 
      else if (this.url_data =="revolutionising-delivery-business-with-proof-of-delivery-software") {
        this.metaTitle = "Proof Of Delivery Software | Confirmation with Visual Evidence";
        this.metaDescription = "Proof of Delivery Software to enhance accuracy and accountability. Electronic proof of delivery docket and visual evidence using software.";
        this.metaKeywords = "proof of delivery software";
        this.metaLink = 'https://recordtime.com.au/blog/revolutionising-delivery-business-with-proof-of-delivery-software';
      } 
      else if (this.url_data == "plant-pre-start-checklist-template") {
        this.metaTitle = "Plant Pre Start Checklist Template for Efficient Pre Start";
        this.metaDescription = "Learn how to create and use plant pre start checklist template with Record TIME. Conduct regular inspections and identify potential threats.";
        this.metaKeywords = "plant pre start checklist template";
        this.metaLink = 'https://recordtime.com.au/blog/plant-pre-start-checklist-template';
      } 
      else if (this.url_data == "pre-start-checklist-template") {
        this.metaTitle = "Customisable Pre Start Checklist Template | Record Time";
        this.metaDescription = "Customisable pre start checklist template to simplify your work and avoid the hassle of creating pre start check from scratch";
        this.metaKeywords = "pre start checklist template";
        this.metaLink = 'https://recordtime.com.au/blog/pre-start-checklist-template';
      } 
      else if (this.url_data == "best-construction-scheduling-software") {
        this.metaTitle = "Best Construction Scheduling Software for Managing Projects";
        this.metaDescription = "Best construction scheduling software and centralised platform to plan, monitor, schedule, manage, track progress in construction industry.";
        this.metaKeywords = "best construction scheduling software";
        this.metaLink = 'https://recordtime.com.au/blog/best-construction-scheduling-software';
      } 
      else if (this.url_data =="employee-time-clock-software") {
        this.metaTitle = "Employee Time Clock Software for Cleaning Business";
        this.metaDescription = "Employee time clock software to generate accurate and automated timesheets and streamline workforce management in the cleaning business.";
        this.metaKeywords = "employee time clock software";
        this.metaLink = 'https://recordtime.com.au/blog/employee-time-clock-software';
      }
      else if (this.url_data == "types-of-digital-forms-you-can-create-using-record-times-docket-book") {
        this.metaTitle = "Earthmoving Docket Book: Elevate Paperwork Management";
        this.metaDescription = "Earthmoving docket book to streamline your jobsite paperwork. Easily access digital form, dockets templates and docket book with Record TIME";
        this.metaKeywords = "earthmoving docket book";
        this.metaLink = 'https://recordtime.com.au/blog/types-of-digital-forms-you-can-create-using-record-times-docket-book';
      } 
      else if (this.url_data == "vehicle-safety-checks-and-list-of-pre-start-checklist") {
        this.metaTitle = "Vehicle Safety Checks - Truck, Ewp, Excavator Pre Start Checklist";
        this.metaDescription = "Vehicle safety checks with vehicle pre start checklist. Truck, Forklift, Ewp and Excavator pre start checklist for vehicle safety inspection";
        this.metaKeywords = "vehicle safety checks";
        this.metaLink = 'https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist';
      }
      else if (this.url_data == "record-time-for-plumbing-business") {
        this.metaTitle = "Optimise and Streamline Plumbing Business with Record TIME";
        this.metaDescription = "Transform your plumbing business with Record TIME. Enhance efficiency, streamline documentation and enhance customer satisfaction.";
        this.metaKeywords = "plumbing business";
        this.metaLink = 'https://recordtime.com.au/blog/record-time-for-plumbing-business';
      } 
      else if (this.url_data == "record-time-qr-code") {
        this.metaTitle = "QR Code of Record TIME to Elevate Your Jobsite and Docketing";
        this.metaDescription = "Transform docketing, and job site management with Record TIME's QR code facility. Simply scan the QR code for easy access.";
        this.metaKeywords = "qr code";
        this.metaLink = 'https://recordtime.com.au/blog/record-time-qr-code';
      } 
      else if (this.url_data == "jobsite-manager") {
        this.metaTitle = "Jobsite Manager Improves Efficiency by Monitoring Daily Progress";
        this.metaDescription = "Advanced progress monitoring solution for jobsite manager to gain comprehensive insights of projects with real time notification.";
        this.metaKeywords = "jobsite manager";
        this.metaLink = 'https://recordtime.com.au/blog/jobsite-manager';
      }
      else if (this.url_data == "document-management-for-businesses") {
        this.metaTitle = "Document management for businesses to transform workflow";
        this.metaDescription = "Document management for businesses for seamless collaboration and productivity on the go remotely with Record TIME";
        this.metaKeywords = "document management for businesses";
        this.metaLink = 'https://recordtime.com.au/blog/document-management-for-businesses';
      } 

      var noscript = document.getElementsByTagName('noscript');
      noscript[0].remove()
    },
    
    methods: {},
  };
  </script>
